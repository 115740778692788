<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import DetailSimulasi from "./detail-simulasi.vue";
// import Swal from "sweetalert2";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Tambah Materi",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  components: {
    Layout,
    PageHeader,
    DetailSimulasi,
  },
  data() {
    return {
      data_jadwal: [],
      data_simulasi: [],
      selected_id_simulasi: "",
      renderDetail: false,
      selected_nama_simulasi: "",
      selected_intake_type: "",
    };
  },
  mounted() {
    // this.getSimulasi();
    this.getDataJadwal();
    this.getDataSimulasi();
  },
  methods: {
    // Method dari masing masing fungsi di dalam file ini

    getDataJadwal() {
      const apiUrl =
        process.env.VUE_APP_BACKEND_URL_VERSION +
        `monitoring/data-jadwal/${this.$route.params.id}`; // Replace with your API endpoint URL
      axios
        .get(apiUrl, {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.access_token,
          },
        })
        .then((response) => {
          var responseData = response.data;
          this.data_jadwal = responseData.data.data;
          console.log(this.data_jadwal);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    getDataSimulasi() {
      const apiUrl =
        process.env.VUE_APP_BACKEND_URL_VERSION +
        `monitoring/data-simulasi/${this.$route.params.id}`; // Replace with your API endpoint URL
      axios
        .get(apiUrl, {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.access_token,
          },
        })
        .then((response) => {
          var responseData = response.data;
          this.data_simulasi = responseData.data.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    async getDataKelompok(id_simulasi, nama_simulasi,intake_type) {
      this.renderDetail = false;
      await this.$nextTick();
      this.selected_id_simulasi = id_simulasi;
      this.selected_nama_simulasi = nama_simulasi;
      this.renderDetail = true;
      this.selected_intake_type = intake_type;
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-12">
              <h4>Monitoring Pelaksanaan : Asesor</h4>
              <hr />
              <div class="row">
                <div class="col-6">
                  <table style="width: 100%; border: 1px">
                    <tr>
                      <td>ID Jadwal</td>
                      <td>:</td>
                      <td>{{ data_jadwal?.idjadwal }}</td>
                    </tr>
                    <tr>
                      <td>Tanggal</td>
                      <td>:</td>
                      <td>
                        {{ data_jadwal?.j_tanggal_mulai }} sd
                        {{ data_jadwal?.j_tanggal_sampai }}
                      </td>
                    </tr>
                    <tr>
                      <td>Jenis Asesmen</td>
                      <td>:</td>
                      <td>{{ data_jadwal?.j_txt_dirkom }}</td>
                    </tr>
                  </table>
                </div>
                <div class="col-6">
                  <table style="width: 100%; border: 1px">
                    <tr>
                      <td>Materi</td>
                      <td>:</td>
                      <td>{{ data_jadwal?.j_materi }}</td>
                    </tr>
                    <tr>
                      <td>PTL</td>
                      <td>:</td>
                      <td>{{ data_jadwal?.j_txt_ptl }}</td>
                    </tr>
                    <tr>
                      <td>Petugas</td>
                      <td>:</td>
                      <td>
                        <span
                          v-for="(
                            petugas, key
                          ) in data_jadwal?.jadwal_petugas_new"
                          :key="key"
                        >
                          {{ petugas.jp_txt_petugas }} {{ " "
                          }}{{ `(${petugas.jp_txt_tugas})` }}
                          <span
                            v-if="
                              !data_jadwal?.jadwal_petugas_new.length == key + 1
                            "
                          >
                            {{ data_jadwal?.jadwal_petugas_new.length }}
                          </span>
                        </span>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="card mt-4">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-4">
                <div class="table-responsive mt-2">
                  <table class="table mb-0 table-bordered table-hover">
                    <thead class="bg-dark text-white">
                      <td class="p-2">Nama Simulasi</td>
                      <td class="p-2">Aksi</td>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(row_data, key_data) in data_simulasi?.materi
                          ?.simulasi"
                        :key="key_data"
                      >
                        <td>{{ row_data.nama_simulasi }}</td>

                        <td class="text-center">
                          <div class="btn-group">
                            <button
                              type="button"
                              class="btn btn-info btn-sm"
                              @click="
                                getDataKelompok(
                                  row_data.id_simulasi,
                                  row_data.nama_simulasi,
                                  row_data.intake_type
                                )
                              "
                            >
                              <i class="fa fa-eye"></i> Detail
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-8" v-if="renderDetail">
                <div>
                  <DetailSimulasi
                    v-if="renderDetail"
                    :id_simulasi="selected_id_simulasi"
                    :nama_simulasi="selected_nama_simulasi"
                    :intake_type="selected_intake_type"
                  />
                </div>
              </div>
              <div class="col-8" v-else>
                <b-alert show variant="primary"
                  >Silahkan pilih detail simulasi !</b-alert
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
